<template>
 <div id="ad" :class="cuid">
    <div ref="bands" class="color_bands">
      <div data-pos="0%" class="color_band color_bands__pmedia"></div>
      <div data-pos="20%" class="color_bandm color_bands__social"></div>
      <div data-pos="40%" class="color_bandm color_bands__seo"></div>
      <div data-pos="60%" class="color_bandm color_bands__digpr"></div>
      <div data-pos="80%" class="color_bandm color_bands__designdev"></div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'

export default {
  name: 'Loader',
  props: {
    repeat: String
  },
  data () {
    return {
      cuid: null
    }
  },
  mounted () {
    const repeatCount = this.repeat ? this.repeat : -1
    const tl = gsap.timeline({ repeatRefresh: true, repeat: repeatCount, repeatDelay: 1 })
    tl.set(this.$refs.bands.querySelector('.color_bands__pmedia'), { right: '0' })
    tl.set(this.$refs.bands.querySelector('.color_bands__social'), { display: 'none' })
    tl.set(this.$refs.bands.querySelector('.color_bands__seo'), { display: 'none' })
    tl.set(this.$refs.bands.querySelector('.color_bands__digpr'), { display: 'none' })
    tl.set(this.$refs.bands.querySelector('.color_bands__designdev'), { display: 'none' })

    tl.set(this.$refs.bands.querySelector('.color_bands__designdev'), { width: '100%' })
    tl.set(this.$refs.bands.querySelector('.color_band'), { opacity: 1 })
    tl.set(this.$refs.bands.querySelectorAll('.color_bandm'), { opacity: 1 })
    tl.from(this.$refs.bands.querySelector('.color_bands__pmedia'), {
      left: '100%',
      ease: 'power2.inOut',
      duration: 0.5,
      onComplete: () => { this.$emit('covered') }
    })
    tl.fromTo(this.$refs.bands.querySelectorAll('.color_bandm'), {
      left: '100%'
    }, {
      left: (c, el) => {
        return el.getAttribute('data-pos')
      },
      display: 'block',
      ease: 'power2.inOut',
      duration: 0.5,
      stagger: 0.1
    }, '-=.2')
    tl.fromTo(this.$refs.bands.querySelectorAll('.color_bandm'),
      {
        left: (c, el) => {
          return el.getAttribute('data-pos')
        }
      }, {
        left: '0%',
        ease: 'power2.inOut',
        duration: 0.5,
        stagger: 0.1,
        delay: 0.5
      })
    tl.set(this.$refs.bands.querySelector('.color_band'), { opacity: 0 })
    tl.set(this.$refs.bands.querySelectorAll('.color_bandm'), { opacity: 0 })
    tl.set(this.$refs.bands.querySelector('.color_bands__designdev'), { opacity: 1 })
    tl.to(this.$refs.bands.querySelector('.color_bands__designdev'), {
      width: 0,
      duration: 0.3,
      onComplete: () => {
        this.$emit('finished')
      }
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#ad {
  width: 100%;
  height: 4px;
  /* background:var(--color-secondary-light-grey); */
  position:relative;
  pointer-events: none;
}
.color_bands {
  position:relative;
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

.color_bands > * {
  position:absolute;
  width:100%;
  height:100%;
/*   opacity:.5; */
}
.color_bands__pmedia{
  background-color: var(--color-team-pmedia)
}
.color_bands__social{
  background-color: var(--color-team-social);
}
.color_bands__seo{
  background-color:var(--color-team-seo);
}
.color_bands__digpr{
  background-color:var(--color-team-digpr);
}
.color_bands__designdev{
  background-color:var(--color-team-designdev);
}
.color_bands {
  overflow:hidden;
}
</style>
