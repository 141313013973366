<template>
  <section>
    <multiselect @select="itemSelected" :show-labels="false" v-model="value" :options="options" :multiple="false" group-values="entries" group-label="group" :group-select="false" placeholder="Type to search" track-by="name" label="name">
        <template v-slot:singleLabel="{ option }"><span class="select--name">{{ option.name }}</span> <span class="select--tag">{{ option.tag }}</span></template>
        <template v-slot:option="{ option }">
          <span v-if="option.$isLabel">{{option.$groupLabel}}</span>
          <span class="select--name">{{ option.name }}</span> <span class="select--tags"><span v-for="tag in option.tag" :key="tag" class="select--tag" :data-type="tag">{{ tag }}</span></span>
          </template>
    </multiselect>
    <pre class="language-json"><code>{{ value }}</code></pre>
  </section>
</template>

<script>
import multiselect from 'vue-multiselect'

export default {
  components: { multiselect },
  data () {
    return {
      options: [],
      value: [],
      token: this.$cookie.getCookie('bn_auth'),
      endpoint_clients: 'https://us-central1-bamboo-nine-internal-tools.cloudfunctions.net/clients',
      endpoint_staff: 'https://europe-west2-bamboo-nine-internal-tools.cloudfunctions.net/staff',
      data_clients: null,
      data_staff: null
    }
  },
  mounted () {
    const clients = fetch(this.endpoint_clients, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.token
      }
    })
      .then(response => response.json())
      .then(response => { return response })

    const staff = fetch(this.endpoint_staff, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.token
      }
    })
      .then(response => response.json())
      .then(response => { return response })

    Promise.all([clients, staff]).then((data) => {
      // Add the raw data objects to the data array
      this.clients = data[0]
      this.staff = data[1]

      // Push clients to the options array
      this.options.push({
        group: 'Clients',
        entries: data[0].map(e => ({ type: 'clients', name: e.client, id: e.id, tag: this.selectTags(e) }))
      })
      // Push staff to the options array
      this.options.push({
        group: 'Staff',
        entries: data[1].map(e => ({ type: 'staff', name: (e.preferredName ? e.preferredName : e.firstName) + ' ' + e.lastName, id: e.id, tag: [e.deparment] }))
      })
    })
  },
  methods: {
    selectTags (data) {
      const arr = []
      if (data.paidMedia) { arr.push('Paid Media') }
      if (data.seo) { arr.push('SEO') }
      return arr
    },
    itemSelected (item) {
      if (item.type === 'clients') {
        const clientData = this.clients.find(el => el.id === item.id)
        // Pull the staff data from the staff array for each of the account Managers.
        console.log(clientData)
        const staffData = {
          accountManager: this.staff.find(el => (el.preferredName === clientData.accountManager)) || this.staff.find(el => (el.firstName === clientData.accountManager)),
          secondaryAccountManager: this.staff.find(el => (el.preferredName === clientData.accountManager)) || this.staff.find(el => (el.firstName === clientData.accountManager))
        }
        console.log(staffData)
      }

      if (item.type === 'staff') {
        console.log(this.staff.find(el => el.id === item.id))
      }
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
  section {
    width: 800px;
  }
  .select--tags{
    display: flex;
    flex-flow: row;
    gap: 4px;
  }
  .select--tag {
    padding:4px 8px;
    border-radius: 2px;
    font-size:14px;
    /* text-transform: uppercase; */
    /* font-weight:500; */
  }
  .select--tag[data-type="SEO"] {
    background:#913fd9;
    color:white;
  }
  .select--tag[data-type="Paid Media"] {
    background:#433696;
    color:white;
  }
  .select--tag[data-type="Technical"] {
    background:#e15cb8;
    color:white;
  }
</style>
