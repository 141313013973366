<template>
  <header v-if="auth_verified && changeRoute">
    <div class="logo">
      <img width="240" height="46" src="https://www.bamboonine.co.uk/wp-content/themes/bamboo-nine-theme/assets/img/logo_bamboo-nine.svg"/>
    </div>
    <div v-if="user" class="user">
      {{ user.data.email }}
    </div>
  <loader repeat="-1" v-if="loading"></loader>
  </header>

  <section class="sectionRouter" v-if="auth_verified && changeRoute">
    <router-view @loading="loadingToggle" />
  </section>
  <section class="loginPage" v-else>

    <div class="logoContainer">
      <svg version="1.1" id="Isolation_Mode" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
      y="0px" viewBox="0 0 167.3 187.3" style="enable-background:new 0 0 167.3 187.3;" xml:space="preserve">
      <path style="fill:#009CA6" class="st0" d="M59.2,96.8c0,0-19.2-19,30.5-96.8C89.8,0,85.6,84,59.2,96.8z"/>
      <path style="fill:#009CA6" class="st0" d="M75,96.8c1.9-36.9,85-70.2,85-70.2S108.6,100.1,75,96.8z"/>
      <path style="fill:#009CA6" class="st0" d="M77,102.6c36.1-7.9,90.3,63.2,90.3,63.2S82.7,135.9,77,102.6z"/>
      <path style="fill:#53565A" class="st1" d="M43.1,101.1c-10.3,0-19.7,3.6-27.1,9.6V77.8c0-4.4-3.6-8-8-8c-4.4,0-8,3.6-8,8v66.4c0,23.8,19.3,43.1,43.1,43.1
      c23.8,0,43.1-19.3,43.1-43.1S66.8,101.1,43.1,101.1z M43.1,171.3c-14.9,0-27.1-12.1-27.1-27.1s12.1-27.1,27.1-27.1
      s27.1,12.1,27.1,27.1S58,171.3,43.1,171.3z"/>
    </svg>
</div>
    <div id="g_id_onload" data-client_id="942299210026-8pudrgm46kkctjdlba3j9ckn4sddcr24.apps.googleusercontent.com"
      data-context="use" data-ux_mode="popup" data-callback="callback" data-itp_support="true">
    </div>
    <div class="g_id_signin" data-type="standard" data-shape="rectangular" data-theme="outline" data-text="signin_with"
      data-size="large" data-logo_alignment="left">
    </div>
    <!-- <h1><span class="login" @click="reOpenGoogleOneTap">Login</span> with your <strong>Bamboo Nine</strong> Google account.</h1> -->
  </section>
  <loader v-if="auth_verified && skipLogin" @finished="skipLogin = false" @covered="changeRoute = true" class="routeLoader" repeat="0"></loader>

</template>
<script>
import { gsap } from 'gsap'
import Loader from '@/components/Loader'

export default {
  data: function () {
    return {
      auth_google_client_id: '942299210026-8pudrgm46kkctjdlba3j9ckn4sddcr24.apps.googleusercontent.com',
      auth_bamboonine_endpoint: 'https://europe-central2-bamboo-nine-internal-tools.cloudfunctions.net/auth',
      auth_verified: false,
      loading: false,
      user: null,
      changeRoute: false,
      skipLogin: true
    }
  },
  components: {
    Loader
  },
  mounted () {
    this.animateLogo()

    window.callback = (reponse) => {
      openGoogleOneTap(reponse)
    }
    const openGoogleOneTap = (response) => {
      console.log('🔐 Verified via Google One Tap.')
      console.log('🌐 Verifying credentials via Bamboo Nine.')

      fetch(this.auth_bamboonine_endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + response.credential
        }
      })
        .then(response => {
          if (response.ok) {
            // Succesfully verified, return the JWT
            return response.json()
          } else {
            // Google token has failed to verify with Bamboo Nine, reshow the login prompt
            this.reOpenGoogleOneTap()
            // Throw an error
            throw new Error('💥 Failed to verify credentials via Bamboo Nine.')
          }
        })
        .then(response => {
          // Set the Bambo Nine auth token as a cookie
          this.$cookie.setCookie('bn_auth', response.result, '30d')
          this.user = JSON.parse(atob(this.$cookie.getCookie('bn_auth').split('.')[1]))

          console.log('🔐 Verified via Bamboo Nine.')
          // Set the auth_verified flag for the frontend to proceed
          this.auth_verified = true
        })
    }

    // If the Bamboo Nine token exists, set the auth_verified flag otherwise launch Google One Tap
    if (this.$cookie.getCookie('bn_auth')) {
      this.auth_verified = true
      this.changeRoute = true
      this.skipLogin = false
    } else {
      // openGoogleOneTap()
    }

    if (this.$cookie.getCookie('bn_auth')) {
      this.user = JSON.parse(atob(this.$cookie.getCookie('bn_auth').split('.')[1]))
      console.log(this.user)
    }

    const googleScript = document.createElement('script')
    googleScript.src = 'https://accounts.google.com/gsi/client'
    googleScript.async = true
    googleScript.defer = true
    document.head.appendChild(googleScript)
  },
  methods: {
    loadingToggle (e) {
      console.log('here')
      console.log(e)
      this.loading = e
    },
    reOpenGoogleOneTap () {
      this.$cookie.removeCookie('g_state') // Remove the Google state cookie that can appear if you've closed One Tap
      window.google.accounts.id.prompt()
    },
    animateLogo () {
      const tl = gsap.timeline()
      tl.from('.logoContainer', { opacity: 0, rotation: -20, transformOrigin: 'bottom left', scale: 0, duration: 1, ease: 'elastic.out(1.05, 1)' }, 0)
      tl.from('path.st0:nth-of-type(1)', { rotation: -40, transformOrigin: 'bottom left', scale: 0, duration: 1, ease: 'elastic.out(1.05, 1)' }, 0)
      tl.from('path.st0:nth-of-type(2)', { rotation: -40, transformOrigin: 'bottom left', scale: 0, duration: 1, ease: 'elastic.out(1.05, 1)' }, 0.1)
      tl.from('path.st0:nth-of-type(3)', { rotation: -100, transformOrigin: 'top left', scale: 0, duration: 1, ease: 'elastic.out(1.05, 1)' }, 0.3)
      // gsap.from('.main-logo .st0', { duration: 1.4, opacity: 0, ease: 'back.out(1.7)', y: '20px', stagger: 0.1 })
      // gsap.from('.main-logo .st1', { duration: 4, opacity: 0, ease: 'back.out(1.7)', stagger: 0.2 }, '1.6')
    }
  }
}
</script>

<style>
.st0{fill:#009CA6;}
.st1{fill:#53565A;}
:root{
  --color-primary-green: #009CA6;
  --color-primary-dark-grey: #53565A;
  --color-secondary-grey: #7B7F84;
  --color-secondary-light-grey: #f4f6f8;
  --color-team-pmedia: #433696;
  --color-team-social: #5C37C6;
  --color-team-seo: #913fd9;
  --color-team-digpr: #C13DC7;
  --color-team-designdev: #E05CB7;
  --color-form: #f4f6f8;
}

html{
  background:#f4f6f8;
}
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}
#app {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  font-family: 'Montserrat', sans-serif;
  /* font-family: 'Open Sans'; */
  color:#53565a;
  background:#f4f6f8;
}
.routeLoader {
  position: absolute!important;
  top:0;
  left:0;
  height: 100vh!important;;
}
.main-logo {
  width: 420px;
}
.st0{
  fill:#53565a;
}
.st1{
  fill:#009ca6;
}

.multiselect__single{
  background:transparent!important;
  padding-left:0!important;
  font-size:16px!important;
}
.multiselect__single,
.multiselect__option {
  /* font-weight: 500; */
  display: flex!important;
  justify-content: space-between;
  align-items:center;
  color:#53565a;
  font-weight:500;
  font-size:16px;
  height:40px;
}

.multiselect__tags.multiselect__tags{
  border:0;
  background:#f4f6f8;
  color:#53565a;
  border-radius:4px!important;
  padding:0 10px!important;
}
.multiselect__tags input{
  line-height:40px!important;
  margin:0!important;
}
.multiselect--active .multiselect__tags.multiselect__tags{
  background:white!important;
  border:1px solid #53565a !important;
}
.multiselect__content-wrapper.multiselect__content-wrapper{
  border:1px solid #53565a;
  border-radius:4px;
  margin-top:10px;
}
.multiselect__select:before{
  border-color:#e5e9ed transparent transparent transparent!important;
}
.multiselect__input.multiselect__input{
  background:transparent;
}

.multiselect__option--highlight,
.multiselect__option--selected  {
  background:#f4f6f8!important;
  color:#53565a!important;
}
.multiselect__select.multiselect__select{
  display:none;
}

#app{
  grid-template-rows: 90px auto;
}
header {
  background:white;
  width:100%;
  height:90px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.logo{
  height:100%;
  display:grid;
  align-items: center;
  padding-left:40px;
  top: -2px;
  left: -2px;
}
.user{
  padding-right:40px;
  display:flex;
  place-items:center;
  color:#53565a;
  font-weight:500;
  font-size:16px;
}
.sectionRouter{
  width:100%;
  height:100%;
  padding: 40px;
  box-sizing: border-box;
  padding:80px 40px;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-form);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-primary-green);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary-green);
}

  h1 {
    font-weight: 300;
    font-size: clamp(1.48rem, 1.26rem + 1.08vw, 2.03rem);
    color:var(--color-primary-green);
    margin:0;
    margin-bottom: 40px;
  }
  strong {
    font-weight: 600;
  }

.login {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  cursor:pointer
}
#credential_picker_container{
  left:unset!important;
  margin:0 auto;
  width:391px!important;
}

.loginPage {
  width: 100%;
  height: 100%;
  grid-row-start: 1;
  grid-row-end: 3;
  display: grid;
  place-content: center;
  padding: 20px;
  box-sizing: border-box;
  gap: 10px;
}
.logoContainer{
  width:160px;
  margin:0 auto;
}
.loginPage h1 {
  margin-top:60px;
  text-align: center;
  /* color:var(--color-primary-dark-grey) */
}
.loginPage strong {
  color:var(--color-primary-green)
}

</style>
