<template>
  <section class="techForm">
    <div class="techForm__text">
        <h1>Submit a <strong>Technical Request</strong></h1>
        <p>Please use this form to send a technical request to Bamboo Nine's <strong>all-star 🦄🌈</strong> development team.</p>
        <p>Requests will get automatically added to the department's workflow and will be picked up by a member of the team.</p>
        <p>👀 Please only mark genuinely urgent requests as urgent.</p>
    </div>
    <div class="container">
    <transition name="slide-up" mode="out-in" >
    <div v-if="success" class="success">
      <h2><strong>“Thank you”</strong> for your request.</h2>
      <a :href="clickUrl">{{clickUrl}}</a>
    </div>
    <div v-else-if="error" class="error">
      <h2><strong>Oh No!</strong> There was a problem.</h2>
      <p>{{ error }}</p>
    </div>
    <form v-else>
      <label for="clientSelector" >Client</label>
      <multiselect :max-height=400 id="clientSelector" @select="itemSelected" :show-labels="false" v-model="value" :options="options" :multiple="false" placeholder="" track-by="name" label="name">
          <template v-slot:singleLabel="{ option }">
            <span class="select--name">{{ option.name }}</span>
            <span class="select--tags"><span v-for="tag in option.tag" :key="tag" class="select--tag" :data-type="tag">{{ tag }}</span></span>
          </template>
          <template v-slot:option="{ option }">
            <span v-if="option.$isLabel">{{option.$groupLabel}}</span>
            <span class="select--name">{{ option.name }}</span>
            <span class="select--tags"><span v-for="tag in option.tag" :key="tag" class="select--tag" :data-type="tag">{{ tag }}</span></span>
            </template>
      </multiselect>

      <label>Type of Issue</label>
      <div class="radioElements">

        <label for="clientTypeTracking">
          <input type="radio" id="clientTypeTracking" name="clientType" value="Tracking" v-model="client_type"/>
          <span class="checkcircle"></span>
          Tracking
        </label>

        <label for="clientTypeFeed">
            <input type="radio" id="clientTypeFeed" name="clientType" value="Product Feed" v-model="client_type" />
            <span class="checkcircle"></span>Product Feed
        </label>

        <label for="clientTypeMediahawk">
          <input type="radio" id="clientTypeMediahawk" name="clientType" value="Mediahawk" v-model="client_type" />
          <span class="checkcircle"></span>Mediahawk
        </label>

        <label for="clientTypeWebsite">
          <input type="radio" id="clientTypeWebsite" name="clientType" value="Website" v-model="client_type" />
          <span class="checkcircle"></span>Website
        </label>

        <label for="clientTypeOther">
         <input type="radio" id="clientTypeOther" name="clientType" value="Other" v-model="client_type" />
          <span class="checkcircle"></span>Other
        </label>

      </div>
      <div>
        <label>Is Everything on Fire?</label>
      <label for="clientUrgency">
        <input type="checkbox" id="clientUrgency" name="clientType" v-model="client_urgent">
        <span class="checkmark"></span>
        Urgent
      </label>
      </div>

      <label for="clientProblem">Description</label>
      <textarea  id="clientProblem" type="text" v-model="client_problem"/>

      <button @click="submitForm" :disabled="loading || value === null"><span v-if="loading">Sending</span><span v-else>Submit Technical Request</span></button>
    </form>
    </transition>
    </div>
  </section>
</template>

<script>
import multiselect from 'vue-multiselect'

export default {
  components: { multiselect },
  data () {
    return {
      options: [],
      value: null,
      token: this.$cookie.getCookie('bn_auth'),
      endpoint_clients: 'https://us-central1-bamboo-nine-internal-tools.cloudfunctions.net/clients',
      endpoint_staff: 'https://europe-west2-bamboo-nine-internal-tools.cloudfunctions.net/staff',
      data_clients: null,
      data_staff: null,
      data_user: null,
      client_problem: '',
      client_type: 'Tracking',
      client_urgent: false,
      loading: false,
      success: false,
      error: false,
      clientData: null,
      clickUrl: null
    }
  },
  mounted () {
    const clients = fetch(this.endpoint_clients, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.token
      }
    })
      .then(response => {
        console.log(response.status)
        console.log(...response.headers)
        return response.json()
      })
      .then(response => { return response })

    const staff = fetch(this.endpoint_staff, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.token
      }
    })
      .then(response => {
        console.log(response.status)
        console.log(...response.headers)
        return response.json()
      })
      .then(response => { return response })

    Promise.all([clients, staff]).then((data) => {
      // Add the raw data objects to the data array
      this.clients = data[0]
      const user = JSON.parse(atob(this.$cookie.getCookie('bn_auth').split('.')[1]))
      this.data_user = data[1].find(e => e.workEmail === user.data.email)
      // Push clients to the options array
      this.options = data[0].map(e => ({ type: 'clients', name: e.client, id: e.id, tag: this.selectTags(e) }))
    })
  },
  methods: {
    selectTags (data) {
      const arr = []
      if (data.paidMedia) { arr.push('Paid Media') }
      if (data.seo) { arr.push('SEO') }
      return arr
    },
    itemSelected (item) {
      this.clientData = this.clients.find(el => el.id === item.id)
      // console.log(clientData)
    },
    submitForm (e) {
      e.preventDefault()
      this.$emit('loading', true)
      this.loading = true
      const user = JSON.parse(atob(this.$cookie.getCookie('bn_auth').split('.')[1]))
      const formData = {
        name: this.value.name + ' - ' + this.client_type,
        dept: this.client_type === 'Website' ? 'dev' : 'data',
        description: this.client_problem,
        tags: [
          'technical request'
        ],
        assignees: [this.data_user.clickup],
        priority: this.client_urgent ? 1 : null,
        notify_all: true,
        custom_fields: [
          { id: 'a9a3d9bb-8192-40cc-8e23-346309843e5b', value: { add: [this.clientData.clickup] } },
          { id: '5ffd1562-a091-420d-bf95-9829c8f2383b', value: user.data.email },
          { id: '98871893-6d70-496b-a6f8-1f69baf4ad86', value: this.clientData.clientUrl ? this.clientData.clientUrl : null },
          { id: '71faebc3-3e85-49bb-b43f-441066f4de60', value: this.clientData.clientName ? this.clientData.clientName : null },
          { id: '8c3fdde0-5f71-403d-8120-5012469fd5c9', value: this.clientData.clientEmail ? this.clientData.clientEmail : null }
        ]
      }

      console.log(formData)
      JSON.stringify(formData)

      const endPoint = 'https://us-central1-bamboo-nine-internal-tools.cloudfunctions.net/clickup-proxy'

      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.$cookie.getCookie('bn_auth')
      }

      fetch(endPoint, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(formData)
      })
        .then(response => {
          console.log(...response.headers)
          console.log(response.status)
          if (response.status !== 200) {
            throw new Error(response.json())
          }
          return response.json()
        })
        .then(response => {
          console.log(response)

          this.$emit('loading', false)
          this.success = true
          this.clickUrl = response.url
        })
        .catch(error => {
          console.log(error)
          this.$emit('loading', false)
          this.error = error.message
        })
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
  section {
    width: 800px;
  }
  .select--tags{
    display: flex;
    flex-flow: row;
    gap: 4px;
  }
  .select--tag {
    padding:4px 8px;
    border-radius: 2px;
    font-size:14px;
    /* text-transform: uppercase; */
    font-weight:400;
  }
  .select--tag[data-type="SEO"] {
    background:#913fd9;
    color:white;
  }
  .select--tag[data-type="Paid Media"] {
    background:#433696;
    color:white;
  }
  .select--tag[data-type="Technical"] {
    background:#e15cb8;
    color:white;
  }

  textarea{
    display:block;
    width:100%;
  }
  .techForm {
    /* height:80vh; */
    height:100%;
    display:grid;
    grid-template-columns: 1fr 584px;
    width:100%;
    max-width:1200px;
    margin:0 auto;
    grid-gap: 32px;
  }
  @media screen and (max-width: 1200px) {
    .techForm {
      grid-template-columns: 1fr;
    }
    .user{
      display:none;
    }
  }

  .techForm form{
    border-radius: 8px;
    padding: 40px;
    height:100%;
    display: flex;
    flex-flow: column;
    box-shadow: rgb(0 0 0 / 4%) 0px 0px 20px 0px;
    box-sizing: border-box;
    width: 100%;
    background:white;
  }
  .techForm form textarea{
    flex-grow:1;
  }

  h1 {
    font-weight: 300;

    font-size: clamp(1.48rem, 1.26rem + 1.08vw, 2.03rem);
    color:var(--color-primary-green);
  }
  h2{
    font-weight: 300;
    font-size: clamp(1.48rem, 1.26rem + 1.08vw, 1.8rem);
    color:white;
  }
  strong {
    font-weight: 600;
  }

  body{
    font-size: clamp(1.00rem, 1.00rem + 0.00vw, 1.00rem);
    font-weight: 500;
    line-height: 1.8em;
  }
  body p{
    line-height: 1.8em;
    font-weight: 500;
  }
  em {
    font-weight: 400;
  }
  input+label {
    font-weight: 400;
  }
  label {
    font-weight: 500;
    line-height: 1.8em;
  }
  h1{
    margin:0;
  }
  .techForm__text p {
    margin:0;
    margin-top:40px;
  }
  .techForm label{
    margin:0;
    margin-bottom:10px;
    display:block;
  }
  .techForm input {
    margin:0;
    margin-bottom:20px;
  }
  .techForm .multiselect {
    margin-bottom:20px;
  }
  .techForm textarea{
    box-sizing: border-box;
    margin-bottom:20px;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;
    color:#53565a;
  }
  .techForm button {
    font-size:clamp(0.94rem, 0.96rem + -0.10vw, 0.89rem);
    text-transform: uppercase;
    color:white;
    background-color:var(--color-primary-green);
    border:0;
    padding:15px 19px;
    width:100%;
    font-family: 'Montserrat', sans-serif;
    font-weight:700;
    transition:all .2s linear;
    cursor:pointer;
  }
 .techForm button[disabled]{
   cursor:not-allowed;
 }
  .techForm button:hover{
    background:#003e42;
  }
  .techForm textarea,
  .techForm input{
    background-color:var(--color-form);
    border:0;
    padding:10px;
    border-radius:4px;
  }
  .techForm textarea:focus{
    outline:1px solid #53565a!important;
    background:white;
  }
  .techForm input[type='checkbox']{
    display:none;
  }
  .checkmark{
    width:20px;
    height:20px;
    background:var(--color-form);
    display:block;
    border-radius:3px;
    position:relative;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    top:-3px;
    left:-1px;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    content:'🔥';
  }

  label[for='clientUrgency'],
  .radioElements label
  {
    display:flex;
    align-items: center;
    gap:8px;
    cursor:pointer;
    margin-bottom:20px;
    font-weight:400;
  }

  .radioElements input{
    display:none;
  }
  .checkcircle{
    width:20px;
    height:20px;
    background:var(--color-form);
    display:block;
    border-radius:10px;
    position:relative;
  }

  .checkcircle:after {
    content: "";
    position: absolute;
    display: none;
    border-radius:10px;
    width: 12px;
    height: 12px;
    border-radius: 10px;
    top: 4px;
    left: 4px;
  }

  input:checked ~ .checkcircle:after {
    display: block;
  }

  .checkcircle:after {
    background:
    var(--color-primary-green);
  }
  .radioElements{
    display:flex;
    gap:16px;
    flex-wrap: wrap;
    row-gap: 0px;
  }

  .success,
  .error{
    background-color:var(--color-primary-green);
    border-radius:8px;
    color:white;
    padding:40px;
    text-align: center;
    display:grid;
    place-content: center;
    height: 100%;
    box-sizing: border-box;
  }
  .error{
    background-color:#e92012c2;
  }
  .slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.success a{
  color: white;
  font-size: 18px;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}
</style>
