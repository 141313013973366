import { createApp } from 'vue'
import { VueCookieNext } from 'vue-cookie-next'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

import App from './App.vue'
import router from './router'

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://921842f6747e4fb29dce0de876e2d12f@o4504020857913344.ingest.sentry.io/4504020865712128',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'bamboonine.co.uk', /^\//]
    })
  ],
  tracesSampleRate: 1.0
})

app.use(router).use(VueCookieNext).mount('#app')

// set default config
VueCookieNext.config({ expire: '30d' })
